import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../styles/Hero"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Hero
      emoji="🤷‍♂️"
      title="NOT FOUND"
      content="You just hit a route that doesn&#39;t exist... the sadness."
    />
  </Layout>
)

export default NotFoundPage
